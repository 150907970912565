
import { Options, mixins } from 'vue-class-component'
import { maska } from 'maska'
import { localStore } from '@/utils/localstore'
import { APP_ROUTER_STORE_KEY } from '@/constants/vars'
import { IInvitation, InvitationModel } from '@/components/invitation/invitation-model'

import AppLogo from '@/components/common/AppLogo.vue'
import WorkspaceMemberMixin from '@/components/workspace/mixins/WorkspaceMemberMixin.vue'
import WorkspaceMixin from '@/components/workspace/mixins/WorkspaceMixin.vue'

@Options({
  components: { AppLogo },
  directives: { maska },
})
export default class JoinWorkspace extends mixins(WorkspaceMemberMixin, WorkspaceMixin) {
  get isAuth() {
    return this.$store.getters.isAuth
  }

  get token() {
    return this.$route.params.token as string
  }

  error = ''

  joinInfo: IInvitation = {}

  async doJoin() {
    const joinedInfo = await this.doJoinByToken(this.token)
    if (!joinedInfo) {
      return
    }
    if (joinedInfo._id) {
      this.$q.notify({
        type: 'positive',
        message: 'joined!',
      })

      // [WorkspaceMixin] Fetch all project again
      this.getWorkspaces()
      // Goto this project
      this.$goto('workspace_board', { workspaceId: this.joinInfo?.workspaceId })
    } else {
      this.$q.notify({
        type: 'negative',
        message: 'Something went wrong!',
      })
    }
  }

  gotoHome() {
    this.$goto('home')
  }

  redirectUrl() {
    const path = this.$router.resolve({
      name: 'join_workspace',
      params: this.$route.params,
    })?.fullPath

    return path
  }

  async created() {
    this.$meta.setMeta({ title: ['Join workspace'] })

    if (!this.isAuth) {
      localStore.setItem(APP_ROUTER_STORE_KEY.REDIRECT_LOGIN, this.redirectUrl())
      this.$goto('login')
      return
    }

    localStore.removeItem(APP_ROUTER_STORE_KEY.REDIRECT_LOGIN)

    this.joinInfo = (await this.getInvitationInfoByToken(this.token)) as InvitationModel
    if (!this.joinInfo?._id || !this.joinInfo.workspaceId) {
      this.error = 'Something went wrong, please check your invitation again or contact to your workspace owner.'
    }
  }
}
